.selectedPopup {
   bottom: 30px;
   left: 55%;
   transform: translateX(-50%);
   background: #6a75ca5b;
   backdrop-filter: blur(4px);
   -webkit-backdrop-filter: blur(4px);
   padding: 16px 32px;
   display: flex;
   color: #fff;
   border-radius: 32px;
   position: fixed;
   align-items: center;
   width: 577px;
   justify-content: space-between;
   z-index: 100;
   grid-gap: 5px;

   &__closeBtn {
      color: #fff;
      padding: 0;
   }

   &__numberWords {
      background: #6a75ca44;
      border-radius: 4px;
      font-size: 12px;
      margin-left: 18px;
      max-width: 35px;
      width: 28px;
      text-align: center;
   }

   &__btn {
      padding: 7px 20px 7px 15px;
      @apply bg-darkPurple dark:bg-darkModeDeepPurple;
      transition: .2s ease-in-out;
      cursor: pointer;
      font-size: 12px;
      border-radius: 6px;
      display: flex;
      align-items: center;

      &:hover {
         background: darken($color:  #6A75CA, $amount: 5);
      }
   }
}

@media (max-width: 768px) {
   .selectedPopup {
      padding: 10px 26px;
      left: 50%;
      width: 90%;

      &__numberWords {
         margin-left: 10px;
      }
      &__btn {
         padding: 7px 10px 7px 5px;
      }
   }
}
