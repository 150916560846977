.chipInput {
      border-radius: 6px;
      margin-top: 5px;

      > div {
         margin-top: 5px;
         min-height: 0 !important;
         padding: 2px 4px;
         @apply border border-lightGray dark:border-darkModeSeparationLine;
         @apply bg-white dark:bg-darkModeDark;
         border-radius: 6px;

         &:focus-within {
           @apply border border-lightPurple dark:border-darkModeDeepPurple #{!important};
          } 
      }

      .MuiChip-root {
         margin: 4px !important;
      }

      input {
         font-family: 'Poppins', sans-serif;
         font-weight: 300;
         @apply text-dark dark:text-whitish;
         padding: 6px 10px;
         font-size: 14px;
      }

      fieldset {
         border: none;
      }

      .MuiInputBase-root {
         padding: 5px 50px 5px 10px !important;
      }
}

.chipInput .MuiButtonBase-root {
   @apply bg-brightLightPurple dark:bg-darkPurple text-purple dark:text-white  #{!important};
   border-radius: 4px;
   transition: .1s ease-in-out;
   margin: 0px 2px !important;

   &:active {
      box-shadow: none;
   }

   &:hover {
      @apply bg-purpleTransparent dark:bg-darkModeDeepPurple #{!important};
   }
}

.chipInput button.MuiButtonBase-root {
   display: none !important;
}


.chipInput .MuiButtonBase-root .MuiChip-deleteIcon {
   color: #8992dc;

   &:hover {
      @apply text-hoverPurple dark:text-lightPurple #{!important};
}
}

.addWordTeacher {
   display: flex;
   flex-direction: column;
   margin: 30px 0;

   &__content {
      max-height: 550px;
      overflow-y: scroll;
      padding: 0 45px;
      position: relative;
   }

   &__content::-webkit-scrollbar {
      display: none;
   }

   &__input {
      width: 100%;
      @apply bg-white dark:bg-darkModeDark text-dark dark:text-white border border-lightGray dark:border-darkModeSeparationLine dark:focus:border-lightPurple;
      border-radius: 5px;
      padding: 5px 50px 5px 10px;
      font-weight: 300;
      outline: none;

      &:hover + .addWordTeacher__removeBtn {
         opacity: 1;
         pointer-events: visible;
      }

      &:focus {
         border: 1px solid #bac0e6;
      }
   }

   &__removeBtn {
      position: absolute;
      top: 15px;
      transform: translateY(-50%);
      left: -40px;
      height: 35px;
      width: 35px;
      z-index: 100;
      cursor: pointer !important;
      opacity: 0;
      border-radius: 3px;

      svg {
         width: 20px !important;
         margin: 5px 0 0 7px;
      }

      &:hover {
         background: #ffffff3c;
         opacity: 1;
         pointer-events: visible;
      }
   }

   &__removeBtnIcon {
      color:  #e66767;
   }
}

@media (max-width: 768px) {
.addWordTeacher {
   &__content {
      padding: 0 20px;
   }

}
}