.uploadImage {
   &__innerWrapper {
      @apply bg-white dark:bg-darkModeDark;
      @apply border border-dashed border-purple dark:border-white;
      border-radius: 24px;
      margin: 0 auto;
      position: relative;
      width: 100%;
      height: 290px;
   }

   &__zoomSlider {
      width: 40% !important;
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
   }
}

.reactEasyCrop_Container {
   height: 160px;
   margin-top: 40px;
}

.reactEasyCrop_CropArea {
   // border: 2px solid red;
}

@media (max-width: 640px) {
.uploadImage {
   &__zoomSlider {
      width: 70% !important;
   }
}
}