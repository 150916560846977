@import '../../../variables.scss';

.addStudent {

   &__switchBtn {
      padding: 7px 0;
      font-size: 13px;
      width: 100px;
      text-align: center;
      color: $purple;
      border-radius: 12px;
      cursor: pointer;
      transition: .3s ease-in-out;
      @apply bg-transparent dark:bg-darkModeTransparentPurple;
      @apply border border-lightPurple dark:border-transparent;

      &:hover {
         @apply bg-brightLightPurple dark:bg-darkModeTransparentPurple;
         @apply dark:border-purple;
      }
   }

   &__switchBtn--active {
      @extend .addStudent__switchBtn;
      @apply bg-white dark:bg-purple;
      border: 1px solid #d6daff;
      color: $purple;
      pointer-events: none;
   }

   &__inputWrapper {
      @apply bg-white dark:bg-darkModeDark;
      @apply border border-lightGray dark:border-darkModeSeparationLine;
      @apply text-dark dark:text-whitish;
      border-radius: 6px;
      display: flex;
      font-weight: 300;
      margin-top: 5px;

      input {
         outline: none;
      }
   }
}

@media (max-width: 640px) {
   .addStudent {
      &__copyLinkBtn {
         position: relative;
         bottom: 0;
         right: 0;
         margin-top: 10px;
      }
   }
}