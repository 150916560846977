.chooseRole {
   width: 700px;

   &__container {
      display: flex;
      justify-content: space-between;
      grid-gap: 10px;
      margin-top: 40px;
   }
   
   &__container--error {
      animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
   }
   
   &__role {
      position: relative;
      border: 2px solid #6a75ca1b;
      border-radius: 18px;
      text-align: center;
      width: 200px;
      height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: .2s ease-in-out;
      color: #6a75ca;
      position: relative;
      -webkit-user-select:none;
      -moz-user-select:none;
      user-select:none;

      img {
         transition: .3s ease-in-out;
      }
      
      &:hover {
         background: #6a75ca1b;
      }
   }

   &__checkRoleIcon {
      position: absolute;
      top: -20px;
      right: -20px;
      opacity: 0;
      transform: scale(0);
   }

   &__role--active {
      @extend .chooseRole__role;
      border: 2px solid #6a75ca;
      box-shadow: 2px 2px 12px rgba(106, 117, 202, 0.24);

      &:hover {
         background: transparent;
      }

      .chooseRole__checkRoleIcon {
         transform: scale(1);
         transition: 0.2s ease;
         opacity: 1;
      }
   }

   &__disabled {
     @extend .chooseRole__role;
     pointer-events: none;
     color: #6a75ca12;
     border: 2px solid #6a75ca07;

     img {
      opacity: 0.1;
     }
   }

   &__roleName {
      font-size: 18px;
      margin-top: 16px;
   }

   &__roleNameLight {
      font-size: 18px;
      font-weight: 300;
      margin-top: 16px;
   }

   &__inputWrapper {
      width: 530px;
      margin: 0 auto;
      margin-top: 20px;
      font-size: 16px;
   }

   &__inputWrapper { 
      @apply bg-white dark:bg-darkModeDark;
      border-radius: 12px;
      transition: .2 ease-in-out;
   }

   &__input {
      @apply bg-white dark:bg-darkModeDark;
      box-shadow: 4px 4px 6px rgba(0, 7, 26, 0.02);
      border-radius: 6px;
      margin-top: 4px;
      padding: 8px 12px;
      outline: none;
      transition: .2 ease-in-out;
      font-size: 14px;
   }

   .MuiAutocomplete-inputRoot {
      input {
         outline: none;
         font-size: 16px;
         @apply text-dark dark:text-white;
         border-radius: 6px;
          border: 1px solid transparent;

      // &:focus {
      //    @apply border-lightPurple dark:border-darkModeDeepPurple;
      // }
      }
      
      input::placeholder {
         font-size: 15px;
      }
    }
    
    .MuiAutocomplete-inputRoot.MuiOutlinedInput-root{
      padding: 2px 20px 2px 40px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid transparent;
      border-radius: 7px;
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border: 1px solid transparent;
    }
    
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      @apply border-lightPurple dark:border-darkModeDeepPurple;
      border-width: 1px;
    }
}

@keyframes shake {
   10%, 90% {
     transform: translate3d(-1px, 0, 0);
   }
   
   20%, 80% {
     transform: translate3d(2px, 0, 0);
   }
 
   30%, 50%, 70% {
     transform: translate3d(-4px, 0, 0);
   }
 
   40%, 60% {
     transform: translate3d(4px, 0, 0);
   }
 }
 

@media (max-width: 768px) {
   .chooseRole {
      width: 100%;

      &__role {
         height: 120px;
         width: 110px;
         border: 1px solid #6a75ca1b;
      }  

      &__role--active {
         border: 1px solid #6a75ca;
      }

      &__roleName {
         font-size: 14px;
      }

      &__checkRoleIcon {
         width: 30px;
         top: -10px;
         right: -10px;
      }

      &__container {
         margin-top: 20px;
      }

      &__inputWrapper {
         width: 100%;
      }
   }
}