@import './../../variables.scss';

.langSelectorWrapper {
   
    .MuiFormControl-root {
      width: 50px;
      box-shadow: none;

      input {
         padding: 6px !important;
      }
   }

    .MuiAutocomplete-inputRoot {
       input {
          outline: none;
          font-size: 12px;
          color: $purple !important;
          text-transform: uppercase;
       }
       
       input::placeholder {
          font-size: 15px;
       }
     }

     .MuiAutocomplete-inputRoot.MuiOutlinedInput-root{
        padding: 0px !important;
     }

      .MuiAutocomplete-inputRoot.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
         @apply border-lightGray dark:border-darkModeSeparationLine;

     }

     
     .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
         border-color: rgba($purple, 0.3)
     }

     .MuiOutlinedInput-notchedOutline {
       border: 1px solid transparent;
     }
     
     .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
       border-color: #bac0e6;
       border-width: 1px;
     }
}


.laguageSelectorMult {
   &__input {
      // border: 1px solid red;
      outline: none;
   }
}

.plainLangSelector {
      @apply bg-white dark:bg-darkModeDark;
      border-radius: 6px;
      padding: 5px 12px;
      outline: none;
      transition: .2 ease-in-out;
      font-size: 14px;

      input {
         padding: 3px 6px !important;
         @apply text-dark dark:text-whitish;
      }

      .MuiOutlinedInput-notchedOutline {
         border-color: transparent;
      }

      .MuiOutlinedInput-root {
         @apply border border-transparent dark:border-darkModeSeparationLine;
      }
      .MuiOutlinedInput-root:hover {
         @apply dark:border-darkModeDeepPurple;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
         @apply border border-lightPurple dark:border-darkModeDeepPurple;
         border-width: 1px;
       }

       .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
         @apply border border-lightPurple dark:border-transparent;
     }

   &__flagWrapper {
      img {
         height: 0.75rem !important;
         box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.223);
      }
   }

}