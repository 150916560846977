// share collection

.shareType {
   color: #6A75CA;
   font-size: 14px;
   padding: 5px 20px;
   border-radius: 6px;
   cursor: pointer;
   transition: .3s ease-in-out;
   @apply bg-transparent dark:bg-darkModeTransparentPurple;
   @apply border border-purple dark:border-transparent;
   
   &:hover {
      @apply bg-brightLightPurple dark:bg-darkModeTransparentPurple;
      @apply dark:border-purple;
   }

}

.shareType--active {
   @extend .shareType;
   background: #6A75CA !important;
   color: #fff;
   pointer-events: none;
}

.shareType--disabled {
   @extend .shareType;
   background: #dbdde1;
   color: #9d9fa1;
   pointer-events: none;
   border: none;
}


.selectedEmailsWrapper {
   input {
      @apply text-dark dark:text-whitish;
      font-size: 14px;
      flex: 1;
      margin-left: 5px;
   }
}

.selectedEmailsWrapper .MuiButtonBase-root {
   // background-color: #eef0ff !important;
   // color: #6A75CA;
   @apply bg-brightLightPurple dark:bg-darkPurple;
   @apply text-purple dark:text-white;

   // @apply bg-brightLightPurple dark:bg-purple;
   // @apply text-purple dark:text-whitish;
   border-radius: 4px;
   transition: .2s ease-in-out;
   margin: 3px !important;

   &:hover {
      // background: #e3e5f8;
      @apply bg-hoverPurple dark:bg-darkModeDeepPurple;
   }
}

.selectedEmailsWrapper .MuiButtonBase-root .MuiChip-deleteIcon {
   color: #6A75CA;
}


// buttons 
.MuiButtonBase-root {
   outline: none !important;
 }
 
 .copyLink {
   .MuiTouchRipple-root {
     color: #6A75CA;
     border-radius: 30px;
   }
 }

@media (max-width: 768px) {
   .shareType {
      padding: 5px 10px;
   }
}