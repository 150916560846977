@import '../../variables.scss';

.header {
   &__userName {
      @apply text-gray dark:text-whitish;
   }

   &__user {

      &:hover .header__userName{
         color: $purple;
         transition: .2s ease-in-out;
      }

      &:hover  .header__userImgWrapper {
         border: 2px solid  #6a75ca38;
         transition: .2s ease-in-out;
      }
   }

   &__user--active {
      .header__userName {
         color: $purple;
      }

      .header__userImgWrapper {
         border: 2px solid  #6a75ca71;
         box-shadow: 0 0 10px rgba(255, 255, 255, 0.321);
      }

      &:hover  .header__userImgWrapper {
         border: 2px solid  #6a75ca71;
      }
   }

   &__selection {
      border: 1px solid #eee;
      position: relative;
   }

   &__selectionHeader {
      padding: 6px 15px 6px 20px;
      border: 1px solid #fff;
      border-radius: 20px;
      font-size: 16px;
      display: flex;
      align-items: center;
      max-width: max-content;
      min-width: 250px;
      cursor: pointer;
      transition: .3s ease-in-out;

      &:hover {
         border: 1px solid #6a75ca71;
         background: rgba(255, 255, 255, 0.479);
      }

      svg {
         color: #6A75CA;
      }
   }


   &__selectionItems {
      position: absolute;
      top: 50px;
      left: 0;
      box-shadow: 2px 2px 6px rgba(26, 35, 35, 0.1);
      border-radius: 20px;
      background: #fff;
      max-width: max-content;
      min-width: 250px;
      overflow: hidden;
   }

   &__selectionItems--closed {
      pointer-events: none;
      opacity: 0;
      @extend .header__selectionItems;
   }

   &__selectionItem {
      display: flex;
      padding: 6px 18px;
      border-bottom: 1px solid #EFF2F8;
      transition: .3s ease-in-out;
      cursor: pointer;
      align-items: center;

      svg {
         color: $purple;
      }

      &:hover {
         background: #e8ebff;
      }

      &:first-child {
         padding: 15px 18px 6px 18px;
      }

      &:last-child {
         border-bottom: none;
         padding: 6px 18px 15px 18px;
      }

   }

   &__searchInput {
      @apply bg-white dark:bg-darkModeDark;
      @apply text-dark dark:text-white;
      padding: 20px 50px 20px 20px;
      border-radius: 40px;
      width: 400px;
      outline: none;
      font-size: 15px;
      box-shadow: 4px 4px 6px rgba(0, 7, 26, 0.08);
      height: 40px;

      &::placeholder {
         @apply text-textLightGray dark:text-darkModeGray;
      }
   }


   &__searchIconWrapper {
      position: absolute;
      top: 0;
      right: 0;
      background: $purple;
      height: 40px;
      width: 40px;
      border-radius: 40px;
      object-fit: contain;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
   }
}


//  toogle menu 
.hamburger-iconWrapper {
   border-radius: 4px;
   padding: 10px;
   width: 35px;
   height: 35px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   transition: $transition;
   display: none;
}

/* Hamburger Icon Styles */
.hamburger-icon {
   display: flex;
   flex-direction: column;
   width: 23px;
   height: 17px;
   justify-content: space-between;
}

.bar {
   width: 20px;
   height: 2px;
   @apply bg-bgPopupLightMode;
   border-radius: 2px;
}

/* Open state for the Hamburger Menu */
.hamburger-iconWrapper.open .bar:nth-child(1) {
   transform: translateY(7.5px) rotate(45deg);
}

.hamburger-iconWrapper.open .bar:nth-child(2) {
   opacity: 0;
}

.hamburger-iconWrapper.open .bar:nth-child(3) {
   transform: translateY(-7.5px) rotate(-45deg);
}


/* Open state for the Menu Items */
.hamburger-iconWrapper.open .libraryMenu__menuItems {
   transform: translateY(0);
   opacity: 1;
   pointer-events: all;
}


@media (max-width: 768px) {
   .header {
      &__searchInput {
         width: 100%;
         flex: 1;
      }
   }

   .hamburger-iconWrapper {
      display: block;
   }
}