$purple: #6A75CA;

.menu__wrapper {
   width: 200px;
   min-width: 200px;
   min-height: 100vh;
   -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
}

.menu {
   height: 100%;
   padding: 40px 0 40px 0;
   width: 200px;
   position: fixed;
   display: flex;
   flex-direction: column;
   overflow-y: scroll;
   scrollbar-width: none;

   &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
}

.menuItem--active div {
   font-weight: 600;
   color: $purple;
}
   
.commingSoonLabel {
   position: absolute;
   background: #6a75ca0e;
   bottom: -24px;
   right: 25px;
   font-weight: 500;
   font-size: 10px;
   color: #6a75cad8;
   padding: 0 5px;
   text-transform: lowercase;
   border-radius: 5px;
}

.sidebar {
   &__menuItem{
      margin-left: 10px;
      width: 100px;
      word-break: break-word;
      display: -webkit-box;
      max-width: 180px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
   }
}

.reportBug {
   border: 1px solid #6a75ca44;
   background: #f5f6fc;
   @apply text-dark dark:text-whitish dark:bg-bgDarkMode dark:hover:bg-darkModeTransparentPurple;
   padding: 5px 10px;
   border-radius: 5px 30px 30px 30px;
   display: flex;
   align-items: center;
   justify-content: center;
   color: #28337E;
   grid-gap: 10px;
   margin-top: 20px;
   font-weight: 500;
   font-size: 14px;
   transition: .2s ease-in-out;

   &:hover {
      background: #e9ebfc;
   }
}

@media (max-width: 768px) {
   .menu__wrapper {
      position: fixed;
      z-index: 49;
      width: 100%;
      backdrop-filter: blur(4px);
      @apply bg-whiteTransparent dark:bg-darkModeDarkTransparent;
      // display: none;
   }

   .menu {
      // border: 1px solid red;
      width: 70%;
      right: 0;
      @apply bg-bgLightMode dark:bg-bgDarkMode;
   }

   .sidebar__menuItem {
      width: auto;
   }
}